<template>
  <div v-if="status == 'VERIFYING'" class="flex flex-col flex-grow justify-center items-center">
    <h2 class="my-8 text-center text-xl font-medium">Verifying account, please wait ...</h2>
    <Icon :icon="['fas', 'spinner']" class="text-primary" size="xl" spin />
  </div>

  <div v-else class="flex flex-col flex-grow justify-center items-center">
    <h2 class="my-8 text-center text-xl font-medium">Verify account</h2>
    <p v-if="status == 'FAILED'"  class="text-base font-medium text-gray-600 max-w-7xl text-center">Apologies, we could not verify your email at this time, please try again later or contact customer support at <span class="text-primary">support@peddle.store</span></p>
    <p v-else-if="status == 'VERIFIED'" class="text-base font-medium text-gray-600 max-w-7xl text-center">Account verification successful</p>
    <p v-else class="text-base font-medium text-gray-600 max-w-7xl text-center">Account verification pending</p>
  </div>
</template>

<script>
import api from '@/services/api.js';

  export default {
    name: 'VerifyAccount',
    data() {
      return {
        token: undefined,
        status: "PENDING"
      }
    },
    methods: {
      async verifyToken() {
        if (!this.token || this.status == "VERIFYING") return;

        this.status = "VERIFYING";

        try {
          const res = await api.post('/users/confirm/email', { token: this.token });

          console.log(res);

          this.status = "VERIFIED"
        } catch (err) {
          this.status = "FAILED";
        }
      }
    },
    mounted() {
      const { token } = this.$route.query;

      if (token) {
        this.token = token;

        this.verifyToken();
      } else {
        window.location.replace('/');
      }
    }
  };
</script>

<style scoped></style>
